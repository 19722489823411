const Overlay = () => {
    return (  
        <div className="overlay">
          <div className="general">
            <div className="backdrop">
              <h1 className="responsive-headline">VB Électrique Inc. - À propos</h1>
              <br /><br />
              <h5>Nous nous engageons à fournir des produits / services de qualité supérieure à nos clients en mettant l'accent sur un service client exceptionnel.</h5>
            </div>
          </div>
        </div>
    )
}

export default Overlay;