import { useRef } from "react";

const AccordionItem = ({ service, onToggle, active }) => {
    const { title, description } = service;
    const contentEl = useRef();

    return (
      <li className={`accordion_item ${active ? "active" : ""}`}>
        <button className="button"  onClick={onToggle}>
        {title} ↓
        </button>
        <div 
            ref={contentEl} 
            className={`answer_wrapper ${active ? "open" : ""}`}
            style={
                active
                ? { height: contentEl.current.scrollHeight }
                : { height: "0px" }
            }
          >
          <div className="answer">{description}</div>
        </div>
      </li>
    );
  };
  
  export default AccordionItem;