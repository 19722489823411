import Services from "./Services";
import { AttentionSeeker } from "react-awesome-reveal";
import { Helmet } from "react-helmet";

const MainContent = () => {
    const data = {
        sectionparagraphlist: [
            {
                "title": "Réparations électriques et entretien",
                "description": "Votre installation électrique présente des signes d'usure ? Nos électriciens peuvent vous aider à identifier et à résoudre tout problème avec votre câblage, vos prises, vos interrupteurs et vos luminaires. Nous offrons également des services d'entretien courant pour assurer le bon fonctionnement de votre système électrique."
            },
            {
                "title": "Mises à niveau électriques",
                "description": "Si votre maison connaît des pannes de courant fréquentes ou si vous avez besoin de prises ou d'appareils d'éclairage supplémentaires, notre équipe peut fournir des mises à niveau électriques qui répondent à vos besoins spécifiques. Nous pouvons installer de nouveaux disjoncteurs, panneaux, parasurtenseurs et plus encore."
            },
            {
                "title": "Installations d'éclairage",
                "description": "Que vous ayez besoin d'éclairer une pièce faiblement éclairée ou d'ajouter un éclairage extérieur pour la sécurité et l'esthétique, nous proposons une variété de solutions d'éclairage adaptées à vos préférences. Nos électriciens peuvent installer des ventilateurs de plafond, des luminaires encastrés, des lustres, des rails d'éclairage et plus encore."
            },
            {
                "title": "Services de domotique et de maison intelligente",
                "description": "Si vous cherchez à améliorer le confort et l'efficacité énergétique de votre maison, nous proposons une gamme de services de domotique et de maison intelligente. De l'installation de thermostats intelligents et de commandes d'éclairage à la configuration de systèmes de cinéma maison et de caméras de sécurité, nous pouvons vous aider à transformer votre maison en un espace intelligent et connecté."
            },
            {
                "title": "Inspections de sécurité électrique",
                "description": "La sécurité de votre famille et de votre maison est notre priorité absolue. Nos électriciens peuvent effectuer des inspections de sécurité électrique complètes pour identifier tout danger potentiel ou violation de code dans votre système électrique. Nous pouvons également fournir des recommandations et des solutions pour vous assurer que votre maison est conforme au code et sécuritaire pour vous et vos proches."
            },
            {
                "title": "Spa / Piscine",
                "description": "Nous sommes également spécialisés dans le raccordement de spas et de piscines à votre système électrique. Nous comprenons que les spas et les piscines sont un excellent moyen de se détendre et de profiter de votre maison, et nous voulons nous assurer qu'ils sont installés correctement et en toute sécurité. Nos électriciens agréés travailleront avec vous pour déterminer le meilleur emplacement pour votre spa ou votre piscine et installeront le câblage et les prises nécessaires pour l'alimenter. Nous veillerons également à ce que l'installation respecte tous les codes et règlements de sécurité. Grâce à notre expertise, vous pouvez profiter de votre spa ou de votre piscine sans vous soucier des problèmes électriques."
            },
            {
                "title": "Chargeurs de voiture électrique",
                "description": "Nous proposons des services d'installation de chargeurs de voiture électrique pour vous aider à alimenter votre véhicule électrique de manière pratique et efficace. Nos électriciens agréés travailleront avec vous pour déterminer le meilleur emplacement pour le chargeur et installer le câblage et les prises nécessaires pour l'alimenter. Nous pouvons également vous conseiller sur le type de chargeur le mieux adapté à vos besoins et aux spécificités de votre véhicule électrique. Nous comprenons que les voitures électriques sont l'avenir et nous nous engageons à fournir des solutions de recharge sûres et fiables à nos clients. Grâce à nos services d'installation de chargeurs de voiture électrique, vous pouvez recharger votre véhicule à la maison, ce qui vous fait gagner du temps et de l'argent tout en réduisant votre empreinte carbone. Contactez-nous dès aujourd'hui pour planifier un service ou une consultation avec l'un de nos électriciens agréés."
            }
        ]
    }

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Services d'électricien résidentiel</title>
                <link rel="canonical" href="services-residentiel" />
            </Helmet>
            <Services data={data.sectionparagraphlist}/>

            <section className="dark-theme-row">
                <div className="content container">
                    <AttentionSeeker effect="pulse" duration={1300}>
                        <div className="section-text">Chez VB Électrique Inc. nous sommes fiers de fournir des services d'électricien résidentiel de qualité supérieure en mettant l'accent sur la satisfaction du client. Contactez-nous dès aujourd'hui pour planifier un service ou une consultation avec l'un de nos électriciens agréés.</div>
                    </AttentionSeeker>
                </div>
            </section>
        </div>
    )
}

export default MainContent;