const Overlay = () => {
    return (  
        <div className="overlay">
          <div className="general">
            <div className="backdrop">
              <h1 className="responsive-headline">Contactez-nous</h1>
              <br /><br />
              <h5>Merci de considérer VB Electrique Inc. pour vos besoins électriques. Nous nous engageons à fournir un service client exceptionnel et des solutions de qualité supérieure à nos clients.</h5>
            </div>
          </div>
        </div>
    )
}

export default Overlay;