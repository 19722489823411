const Overlay = () => {
    return (  
        <div className="overlay">
          <div className="general">
            <div className="backdrop">
              <h1 className="responsive-headline">VB Electrique Inc. - About Us</h1>
              <br /><br />
              <h5>We are committed to providing top-quality products / services to our customers with a focus on exceptional customer service.</h5>
            </div>
          </div>
        </div>
    )
}

export default Overlay;