import { AttentionSeeker } from "react-awesome-reveal";

const Services = () => {
      return (
        <div>
            <section className="light-theme-row">
                <div className="content container">
                    <AttentionSeeker effect="pulse" duration={1300}>
                        <p className="section-text">Our company was founded in 2023 with the mission of providing a superior service for all your electrical needs. Since then, we have been dedicated to delivering innovative and reliable products and services to our customers, helping them accomplish their projects.</p>
                    </AttentionSeeker>
                </div>
            </section>

            <section className="dark-theme-row">
                <div className="content container">
                    <AttentionSeeker effect="pulse" duration={1300}>
                        <p className="section-text">At VB Electrique Inc., we believe that our success is driven by our team of dedicated professionals. Our team consists of licensed and experienced electricians who are passionate about their work and committed to exceeding customer expectations. They are equipped with the latest tools and technology to deliver top-quality solutions to our customers.</p>
                    </AttentionSeeker>
                </div>
            </section>

            <section className="light-theme-row">
                <div>
                    <AttentionSeeker effect="pulse" duration={1300}>
                        <div className="google-maps">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d44601.83860421264!2d-73.90225278721141!3d45.67863047900159!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc8d7d71aa26b5f%3A0xef0e85535a27fa50!2s6%20Pl.%20d&#39;Argenson%2C%20Blainville%2C%20QC%20J7C%204H2!5e0!3m2!1sen!2sca!4v1678851241854!5m2!1sen!2sca" 
                                width="600"
                                height="450"
                                title="vbelectrique Address"
                                style={{ border: 0, maxWidth: "-webkit-fill-available" }}
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade">
                            </iframe>
                        </div>
                    </AttentionSeeker>
                </div>
            </section>
            
            <section className="dark-theme-row">
                <div className="content container">
                    <AttentionSeeker effect="pulse" duration={1300}>
                        <p className="section-text">We are proud to serve Blainville and surrounding areas, and we strive to build long-lasting relationships with our customers. Our commitment to customer satisfaction is reflected in the positive feedback we receive.</p>
                    </AttentionSeeker>
                </div>
            </section>

            <section className="light-theme-row">
                <div className="content container">
                    <AttentionSeeker effect="pulse" duration={1300}>
                        <p className="section-text">We also believe in giving back to our community, and we support various local initiatives that align with our values.</p>
                    </AttentionSeeker>
                </div>
            </section>

            <section className="dark-theme-row">
                <div className="content container">
                    <AttentionSeeker effect="pulse" duration={1300}>
                        <p className="section-text">Thank you for considering VB Electrique Inc. for your future projects. We look forward to serving you and exceeding your expectations.</p>
                    </AttentionSeeker>
                </div>
            </section>

            <section className="light-theme-row">
                <div className="content container">
                    <AttentionSeeker effect="pulse" duration={1300}>
                        <p className="section-text">Please contact us for more information about our products / services or to schedule a consultation with one of our licensed electricians.</p>
                    </AttentionSeeker>
                </div>
            </section>
        </div>
      )
}

export default Services;