import { AttentionSeeker } from "react-awesome-reveal";

const Services = () => {
    const data = [
        {
            "description": "Notre entreprise a été fondée en 2023 avec la mission de fournir un service supérieur pour tous vos besoins électriques. Depuis lors, nous nous consacrons à fournir des produits et services innovants et fiables à nos clients, en les aidant à réaliser leurs projets."
        },
        {
            "description": "Chez VB Électrique Inc. nous croyons que notre succès repose sur notre équipe de professionnels dévoués. Notre équipe est composée d'électriciens agréés et expérimentés, passionnés par leur travail et déterminés à dépasser les attentes des clients. Ils sont équipés des outils derniers cri et technologies pour fournir des solutions de haute qualité à nos clients."
        },
        {
            "description": "Nous sommes fiers de desservir Blainville et ses environs et nous nous efforçons de bâtir des relations durables avec nos clients. Notre engagement envers la satisfaction de nos clients se reflète dans les commentaires positifs que nous recevons."
        },
        {
            "description": "Nous croyons également qu'il faut redonner à notre communauté et nous soutenons diverses initiatives locales qui correspondent à nos valeurs."
        },
        {
            "description": "Merci de considérer VB Électrique Inc. pour vos futurs projets. Nous sommes impatients de vous servir et de dépasser vos attentes."
        },
        {
            "description": "Veuillez nous contacter pour plus d'informations sur nos produits / services ou pour planifier une consultation avec l'un de nos électriciens agréés."
        }
    ]

    const services = data.map((service, i) => {
        let divClass = i %2 === 0 ? 'light-theme-row' : 'dark-theme-row';

        return (
            <section className={divClass} key={i}>
                <div className="content container">
                    <AttentionSeeker effect="pulse" duration={1300}>
                        <p className="section-text">{service.description}</p>
                    </AttentionSeeker>
                </div>
            </section>
        );
      });

      return (
        <div>
            {services}
        </div>
      )
}

export default Services;