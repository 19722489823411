import Video from "../Fr-Components/PageElements/Homepage/Video";
import Overlay from "../Fr-Components/PageElements/Homepage/Overlay";
import MainContent from "../Fr-Components/PageElements/Homepage/MainContent";
import { Waypoint } from 'react-waypoint';
import { waypointEnter, waypointLeave } from "../init";

const Home = () => {
  return (
    <div>
      <div className="home-above-the-fold">
        <Video />
        <Overlay />
      </div>
      <Waypoint onEnter={waypointEnter} onLeave={waypointLeave} topOffset="50px"/>
      <div className="home-under-the-fold">
        <MainContent />
      </div>
    </div>
    );
  };
  
  export default Home;