import React, { useState, useRef } from "react";
import $ from "jquery";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
  const form = useRef();
  const [contactName, setName] = useState("");
  const [contactPhone, setPhone] = useState("");
  const [contactEmail, setEmail] = useState("");
  const [contactMessage, setMessage] = useState("");
  const [validMessage, setValidMessage] = useState("Please fill out all the fields.");
  const [validationMessage, setValidationMessage] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const ToggleMessage = (validMessage) => {
    setValidMessage(validMessage);
  };

  function submitRequest(e) {
    e.preventDefault();

    setValidationMessage(false);

    if (
      !form.current.contactName.value |
      !form.current.contactPhone.value |
      !form.current.contactEmail |
      !form.current.contactMessage
    ) {
      if ($(".validation-message").hasClass("hidden")) {
        setValidationMessage(true);
      }
    } else {
        if (formSubmitted) {
          if ($(".validation-message").hasClass("hidden")) {
            $("#image-loader").fadeOut();
            ToggleMessage("We already received your request.");
            setValidationMessage(true);
            
            return;
          }
        }

      $("#image-loader").fadeIn();
      emailjs
        .sendForm(
          "service_711dkza",
          "template_cts85vh",
          form.current,
          "PugOh-WHgHuI81Dij"
        )
        .then(
          (result) => {
            $("#image-loader").fadeOut();
            setFormSubmitted(true);
            ToggleMessage("Thank you! We will be contacting you shortly.");
            setValidationMessage(true);
          },
          (error) => {
            $("#image-loader").fadeOut();
            ToggleMessage("There was an error");
            setValidationMessage(true);
          }
        )
        .catch(
          (error) => {
            $("#image-loader").fadeOut();
            ToggleMessage("There was an error");
            setValidationMessage(true);
          }
        )
        ;
    }
  }

  return (
    <div className="row" style={{ paddingTop: "25px" }}>
      <form
        ref={form}
        method="post"
        className="contactForm"
        id="contactForm"
        name="contactForm"
        onSubmit={submitRequest}
        netlify="true"
      >
        <fieldset>
          <div className="row">
            <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
              <div>
                <label htmlFor="contactName">Name</label>
                <input
                  type="text"
                  size="35"
                  id="contactName"
                  name="contactName"
                  value={contactName}
                  placeholder="John Doe"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div>
                <label htmlFor="contactPhone">Phone Number</label>
                <input
                  type="text"
                  size="35"
                  id="contactPhone"
                  name="contactPhone"
                  placeholder="(555) 555-5555"
                  value={contactPhone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>

              <div>
                <label htmlFor="contactEmail">Email</label>
                <input
                  type="text"
                  size="35"
                  id="contactEmail"
                  name="contactEmail"
                  value={contactEmail}
                  placeholder="person@example.com"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>

            <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
              <label htmlFor="contactMessage">Message</label>
              <textarea
                cols="50"
                rows="7"
                id="contactMessage"
                name="contactMessage"
                value={contactMessage}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="I live in Blainville and need a new electrical panel so that I can install an electric car charger."
              ></textarea>
            </div>
          </div>
          <div className="row">
            <div className="twelve columns">
              <div>
                <button className="submit">Submit</button>
                <span id="image-loader">
                  <img alt="" src="images/loader.gif" />
                </span>
              </div>
            </div>
          </div>
          <div
            className={
              "validation-message text-center " +
              (validationMessage ? "" : "hidden")
            }
          >
            {validMessage}
          </div>
        </fieldset>
      </form>
    </div>
  );
};

export default ContactForm;
