import { slide as Menu } from 'react-burger-menu';
import { englishMenuItems } from '../../menuItems';
import MenuItems from './MenuItems';
import MobileMenuItems from './MobileMenuItems';
import { Link } from 'react-router-dom';
import LanguageSwitch from '../../GlobalComponents/LanguageSwitch';

const Navbar = () => {
  return (
    <div>
      <div className="desktop">
        <nav>
          <Link to="/home">
            <img src={"/media/TransparentLogoLight.svg"} alt="Logo" className="logo-nav desktop"/>
          </Link>
          <ul className="menus">
            {englishMenuItems.map((menu, index) => {
              return <MenuItems items={menu} key={index} />;
            })}
            <LanguageSwitch />
          </ul>
        </nav>
      </div>
      <div className="mobile">
        <Menu 
          left
          customBurgerIcon={ <img src={"/media/hamburger-menu.svg"} alt="burger icon"/> }
          isOpen={false}
        >
          {englishMenuItems.map((menu, index) => {
            return <MobileMenuItems items={menu} key={index} />;
          })}
        </Menu>
        <div className="float-right">
          <LanguageSwitch />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
