import { Link } from "react-router-dom";
import FontAwesome from "react-fontawesome";
import { closeAllMenusOnEsc } from "../../init";

const MobileMenuItems = ({ items }) => {
  return (
    <li className="menu-items">
      {items.url && items.submenu ? (
        <>
          <button type="button" aria-haspopup="menu">
            {window.innerWidth < 960 ? (
              items.title
            ) : (
              <Link
                to={items.url}
                key={items.url}
                onTouchEnd={() => closeAllMenusOnEsc()}
              >
                {items.title}
              </Link>
            )}

            {window.innerWidth < 960 ? null : window.innerWidth > 960 ? (
              <span>&raquo;</span>
            ) : (
              <span className="arrow" />
            )}
          </button>
        </>
      ) : !items.url && items.submenu ? (
        <>
          <button type="button" aria-haspopup="menu" key="service">
            {items.title} <span className="arrow" />
          </button>

          {items.submenu.map((subItem, index) => {
            return (
              <Link
                to={subItem.url}
                key={index}
                onTouchEnd={() => closeAllMenusOnEsc()}
              >
                <FontAwesome name="bolt" style={{ color: "#fcc419" }} />
                &nbsp;&nbsp;
                {subItem.title}
              </Link>
            );
          })}
        </>
      ) : (
        <Link
          to={items.url}
          key={items.url}
          onTouchEnd={() => closeAllMenusOnEsc()}
        >
          {items.title}
        </Link>
      )}
    </li>
  );
};

export default MobileMenuItems;
