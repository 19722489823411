import Services from "./Services";
import { AttentionSeeker } from "react-awesome-reveal";
import { Helmet } from "react-helmet";

const MainContent = () => {
    const data = {
        sectionparagraphlist: [
            {
                "title": "Electrical Installations",
                "description": "If you're opening a new business or renovating an existing space, our electricians can handle all your electrical installation needs. We can install new electrical systems, including wiring, panels, breakers, and more. We also install lighting fixtures, ceiling fans, and other electrical devices."
            },
            {
                "title": "Electrical Repairs and Maintenance",
                "description": "If your electrical system is experiencing issues or showing signs of wear and tear, our electricians can help. We can troubleshoot and repair any problems with your wiring, switches, outlets, and lighting. We also offer routine maintenance services to keep your electrical system running smoothly."
            },
            {
                "title": "Electrical Upgrades",
                "description": "If your business needs additional power or you're looking to upgrade your electrical system to a more efficient and cost-effective one, we can help. Our team can install new circuits, panels, transformers, and other electrical devices to meet your specific needs."
            },
            {
                "title": "Lighting Upgrades and Retrofits",
                "description": "If you're looking to upgrade your lighting to save energy and reduce costs, we offer a range of lighting solutions, including LED retrofits and installations. We can also install occupancy sensors and other lighting controls to maximize your energy savings."
            }
        ]
    }

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Commercial Electrician Services</title>
                <link rel="canonical" href="commercial" />
            </Helmet>
            <Services data={data.sectionparagraphlist}/>

            <section className="light-theme-row">
                <div className="content container">
                    <AttentionSeeker effect="pulse" duration={1300}>
                        <div className="section-text">At VB Electrique Inc. we take pride in delivering top-quality commercial electrician services with a focus on customer satisfaction. Contact us today to schedule a service or consultation with one of our licensed electricians.</div>
                    </AttentionSeeker>
                </div>
            </section>
    </div>
    )
}

export default MainContent;