import React from 'react';
import Accordion from './Accordion';

const MobileServices = (props) => {

    return (
        <div className="container">
            <Accordion list={props.data.sectionparagraphlist}/>
        </div>
    )
}

export default MobileServices;