const Overlay = () => {
    return (  
        <div className="overlay">
          <div className="general">
            <div className="backdrop">
              <h1 className="responsive-headline">Contact Us</h1>
              <br /><br />
              <h5>Thank you for considering VB Electrique Inc. for your electrical needs. We are committed to providing exceptional customer service and top-quality solutions to our customers.</h5>
            </div>
          </div>
        </div>
    )
}

export default Overlay;