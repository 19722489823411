const Video = () => {
    return (
        <video 
            src={"/media/electrician.mp4"} 
            muted 
            autoPlay 
            loop 
            style={{
            width: "100%",
            height: "100vh",
            objectFit: "cover",
            zIndex: "1",
            overflow: "hidden"
            }}
        />
    )
}
export default Video;