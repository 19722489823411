import { Fade } from "react-awesome-reveal";

const Partners = () => {
    const partnerList = [
        {
            name: "ABB",
            url: "https://www.tnb.ca/en/",
            logo: "/media/company-logos/ABB.svg"
        },
        {
            name: "Lutron",
            url: "https://www.lutron.com/en-US/pages/default.aspx",
            logo: "/media/company-logos/lutron-electronics-vector-logo.svg"
        },
        {
            name: "Scheider Electric",
            url: "https://www.se.com/ww/en/",
            logo: "/media/company-logos/schneider-electric-logo-jpg.svg"
        },
        {
            name: "Ouellet",
            url: "https://ouellet.com/en-ca/default.aspx",
            logo: "/media/company-logos/ouelletlogo.svg"
        },
        {
            name: "Champion Generators",
            url: "https://www.championpowerequipment.com/",
            logo: "/media/company-logos/champion.svg"
        },
        {
            name: "Leviton",
            url: "https://www.leviton.com/en",
            logo: "/media/company-logos/leviton-logo-preferred-web.svg"
        }
    ]

    const partners = partnerList.map(function (partner) {
        return (
          <div className="col-4 col-sm-4 col-md-2 col-lg-2 partner" key={partner.name}>
            <a href={partner.url}>
              <img src={partner.logo} alt={partner.name} width="150"/>
            </a>
          </div>
        );
      });

    return (
        <div className="light-theme-row partner-wrapper">
            <Fade top>
                <div className="container">
                    <div className="row">
                        {partners}
                    </div>
                </div>
            </Fade>
        </div>
    )
}

export default Partners;