const Overlay = () => {
    return (  
        <div className="overlay">
          <div className="general generator">
            <div className="backdrop">
              <h1 className="responsive-headline">Portable Generator Installation for Homes</h1>
              <h5>At VB Electrique Inc. we understand that power outages can be frustrating and disruptive, especially for homeowners. That's why we offer portable generator installation services to provide reliable backup power to your home in the event of a power outage. Our licensed and experienced electricians can help you choose the right size and type of generator to meet your specific needs.</h5>
            </div>
          </div>
        </div>
    )
}

export default Overlay;