import Services from "./Services";
import { AttentionSeeker } from "react-awesome-reveal";
import { Helmet } from "react-helmet";

const MainContent = () => {
    const data = {
        sectionparagraphlist: [
            {
                "title": "Electrical Repairs and Maintenance",
                "description": "Is your electrical system showing signs of wear and tear? Our electricians can help you identify and fix any issues with your wiring, outlets, switches, and lighting fixtures. We also offer routine maintenance services to keep your electrical system running smoothly."
            },
            {
                "title": "Electrical Upgrades",
                "description": "If your home is experiencing frequent power outages or you need additional outlets or lighting fixtures, our team can provide electrical upgrades that meet your specific needs. We can install new circuit breakers, panels, surge protectors, and more."
            },
            {
                "title": "Lighting Installations",
                "description": "Whether you need to brighten up a dimly lit room or add outdoor lighting for security and aesthetics, we offer a variety of lighting solutions to suit your preferences. Our electricians can install ceiling fans, recessed lighting, chandeliers, track lighting, and more."
            },
            {
                "title": "Home Automation and Smart Home Services",
                "description": "If you're looking to enhance your home's convenience and energy efficiency, we offer a range of home automation and smart home services. From installing smart thermostats and lighting controls to setting up home theater systems and security cameras, we can help you transform your home into a smart and connected space."
            },
            {
                "title": "Electrical Safety Inspections",
                "description": "The safety of your family and home is our top priority. Our electricians can conduct comprehensive electrical safety inspections to identify any potential hazards or code violations in your electrical system. We can also provide recommendations and solutions to ensure your home is up to code and safe for you and your loved ones."
            },
            {
                "title": "Hot Tub / Pool",
                "description": "We also specialize in hooking up hot tubs and pools to your electrical system. We understand that hot tubs and pools are a great way to relax and enjoy your home, and we want to ensure that they are installed safely and properly. Our licensed electricians will work with you to determine the best location for your hot tub or pool and install the necessary wiring and outlets to power it. We'll also ensure that the installation meets all safety codes and regulations. With our expertise, you can enjoy your hot tub or pool without any worries about electrical issues."
            },
            {
                "title": "Electric Car Chargers",
                "description": "We offer electric car charger installation services to help you power up your electric vehicle conveniently and efficiently. Our licensed electricians will work with you to determine the best location for the charger and install the necessary wiring and outlets to power it. We can also provide advice on the type of charger that would best suit your needs and the specific requirements of your electric vehicle. We understand that electric cars are the future, and we are committed to providing safe and reliable charging solutions to our customers. With our electric car charger installation services, you can charge your vehicle at home, saving you time and money while reducing your carbon footprint. Contact us today to schedule a service or consultation with one of our licensed electricians."
            }
        ]
    }

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Residential Electrician Services</title>
                <link rel="canonical" href="residential" />
            </Helmet>
            <Services data={data.sectionparagraphlist}/>

            <section className="dark-theme-row">
                <div className="content container">
                    <AttentionSeeker effect="pulse" duration={1300}>
                        <div className="section-text">At VB Electrique Inc. we take pride in delivering top-quality residential electrician services with a focus on customer satisfaction. Contact us today to schedule a service or consultation with one of our licensed electricians.</div>
                    </AttentionSeeker>
                </div>
            </section>
        </div>
    )
}

export default MainContent;