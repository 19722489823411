import Services from "./Services";
import { AttentionSeeker } from "react-awesome-reveal";
import { Helmet } from "react-helmet";

const MainContent = () => {
    const list = [
        {
            "title": "Évaluation du site",
            "description": "Avant de commencer le processus d'installation, nos électriciens procéderont à une évaluation du site afin de déterminer le meilleur emplacement pour votre génératrice. Nous évaluerons également vos besoins en énergie et vous aiderons à choisir la bonne taille et le bon type de générateur pour répondre à vos besoins."
        },
        {
            "title": "Installation génératrice",
            "description": "Nos électriciens installeront la génératrice conformément aux spécifications du fabricant et aux codes et règlements locaux. Nous installerons également un commutateur de transfert qui commutera automatiquement votre source d'alimentation vers la génératrice en cas de panne de courant."
        },
        {
            "title": "Entretien et réparation de génératrice",
            "description": "Nous comprenons l'importance de garder votre génératrice en bon état de fonctionnement. C'est pourquoi nous proposons des services d'entretien de routine pour garantir que votre groupe électrogène est prêt à fonctionner lorsque vous en avez besoin. Nous pouvons également réparer tout problème avec votre génératrice pour le maintenir en bon état de fonctionnement."
        },
        {
            "title": "Remplacement de génératrice",
            "description": "Si votre génératrice existante n'est plus fiable ou efficace, notre équipe peut vous aider à la remplacer par une nouvelle génératrice de secours Champion. Nous retirerons l'ancienne génératrice, installerons la nouvelle et veillerons à ce qu'elle respecte tous les codes et règlements de sécurité."
        }
    ]

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Installation de génératrice de secours Champion</title>
                <link rel="canonical" href="/generatrice-de-secours" />
            </Helmet>
            <Services data={list}/>

            <section className="light-theme-row">
                <div className="content container">
                    <AttentionSeeker effect="pulse" duration={1300}>
                        <div className="section-text">Chez VB Electrique Inc, nous sommes fiers de fournir des services d'installation de groupes électrogènes de secours Champion de qualité supérieure en mettant l'accent sur la satisfaction du client. Avec nos services d'installation, vous pouvez être assuré d'avoir une alimentation fiable en cas de panne de courant. Contactez-nous dès aujourd'hui pour planifier un service ou une consultation avec l'un de nos électriciens agréés.</div>
                    </AttentionSeeker>
                </div>
            </section>
    </div>
    )
}

export default MainContent;