const Overlay = () => {
    return (
        <div className="overlay">
          <h1 className="responsive-headline" style={{ display: "none" }}> VB Électrique Inc.</h1>
          <img src={"/media/TransparentLogoLight.svg"} alt="Logo" className="logo"/>
          <h5>Faites confiance à l’entrepreneur électricien VB Électrique Inc. pour tous vos besoins d’installations et de branchements électriques.</h5>
          <h5 className="regions">Régions de services principal: Laurentides, Lanaudière et Laval</h5>
        </div>
    )
}

export default Overlay;