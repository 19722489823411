const Overlay = () => {
    return (  
        <div className="overlay">
          <div className="general generator">
            <div className="backdrop">
              <h1 className="responsive-headline">Champion Stand-by Generator Installation</h1>
              <h5>At VB Electrique Inc. we offer Champion stand-by generator installation services to provide reliable and continuous power to your home or business in the event of a power outage. Our licensed and experienced electricians can help you choose the right size and type of generator to meet your specific needs.</h5>
            </div>
          </div>
        </div>
    )
}

export default Overlay;