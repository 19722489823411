const Overlay = () => {
    return (  
        <div className="overlay">
          <div className="general commercial">
            <div className="backdrop">
              <h1 className="responsive-headline">Services d'électricien commercial</h1>
              <h5>Chez VB Électrique Inc, nous comprenons l'importance d'un système électrique sûr et fiable pour votre propriété commerciale. Notre équipe d'électriciens agréés et expérimentés est là pour fournir des services électriques fiables et professionnels aux entreprises de Blainville.</h5>
            </div>
          </div>
        </div>
    )
}

export default Overlay;