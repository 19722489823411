import ContactForm from "./ContactForm";
import { Helmet } from "react-helmet";

const MainContent = () => {
    return (
        <div>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Contactez-nous</title>
            <link rel="canonical" href="contactez-nous" />
        </Helmet>
        <section className="dark-theme-row">
            <div className="content container">
                <div className="row">
                    <div className="section-text" style={{paddingBottom: "1em"}}>
                    Si vous avez des questions ou souhaitez planifier une consultation avec l'un de nos électriciens agréés, veuillez nous contacter en utilisant l'une des méthodes suivantes: 
                    </div>
                    <hr />
                    <div style={{textAlign: "center",lineHeight: "20px",marginTop: "1em"}}>
                            <p>Téléphone: <a href="tel:5146624693">(514) 662-4693</a></p>
                            <p>Courriel: <a href="mailto:info@vbelectrique.com">info@vbelectrique.com</a></p>
                            <p>Adresse: 6 Place d'Argenson, Blainville J7C 4H2 Québec</p>
                        </div>
                </div>
            </div>
        </section>

        <section className="light-theme-row">
            <div className="content container">
                <div className="row">
                    <div className="section-text">Vous pouvez également utiliser le formulaire ci-dessous pour nous envoyer un message. Un de nos représentants vous répondra dans les plus brefs délais.</div>
                </div>
                <ContactForm />
            </div>
        </section>
    </div>
    )
}

export default MainContent;