import FontAwesome from "react-fontawesome";
import Partners from "../GlobalComponents/Partners";

const Footer = () => {
  const data = {
    copyright: "Copyright",
    designed: "Design by ",
    developer: {
      email: "tweetyboopdev2022@gmail.com",
      subject: "New Website Design Inquiry from VB Electrique Inc.",
      body: "Please indicate your needs for a new website and/or design. Specify what type of technology, particular needs and features and timeframe."
    }
  }
  
  const networkList = [
    {
        "name": "Facebook",
        "url": "https://www.facebook.com/vbelectrique",
        "className": "facebook"
    },
    {
        "name": "Yelp",
        "url": "https://www.yelp.com/biz/vb-%C3%A9lectrique-inc-blainville-4",
        "className": "yelp"
    },
    {
        "name": "Construction 411",
        "url": "https://www.construction411.com/electricians/blainville/vb-electrique/",
        "className": "map-marker"
    }
]

  const networks = networkList.map(function (network) {
    return (
      <li key={network.name}>
        <a href={network.url}>
          <FontAwesome name={network.className} />
        </a>
      </li>
    );
  });

  const seoKeywords = [
    'effectuer les travaux',
    'entrepreneurs électriciens',
    'entrée électrique',
    'faire appel',
    'installations électriques',
    'maître électricien à blainville',
    'panneau électrique',
    'résidentielle commerciale',
    'service rapide',
    'travaux électriques',
    'électriciens qualifiés',
    'électricité résidentiel',
    'équipe d électriciens'
  ]

  let keywords = seoKeywords.map(function(keyword) {
    return <span key={keyword}>{keyword} </span>;
  });

    return (
      <footer >
        <Partners />
        <div className="dark-theme-row">
          <ul className="social-links">
            <img src="/media/CMEQ.png" alt="CMEQ" width="200" className="cmeq-logo desktop"/>
            <img src="/media/CMEQ-small.svg" alt="CMEQ" width="45" className="cmeq-logo mobile"/>
            {networks}
          </ul>

          <div className="copyright">
            <p>
              &copy; {data.copyright} {new Date().getFullYear()} VB Électrique Inc.
            </p>
            <p>
              Design by{" "}
              <a
                href={
                  "mailto:" +
                  data.developer.email +
                  "?subject=" +
                  data.developer.subject +
                  "&body=" +
                  data.developer.body
                }
              >
                TweetyBoopDev
              </a>
            </p>
          </div>

          <div id="keywords" className="keywords">
            {keywords} 
          </div>

          <div id="go-top">
            <a className="smoothscroll" title="Back to Top" href="#home">
              <i className="icon-up-open"></i>
            </a>
          </div>
        </div>
      </footer>
    );
  }

export default Footer;
