const Overlay = () => {
    return (
        <div className="overlay">
          <h1 className="responsive-headline" style={{ display: "none" }}> VB Electrique Inc.</h1>
          <img src={"/media/TransparentLogoLight.svg"} alt="Logo" className="logo"/>
          <h5>Trust the electrical contractor VB Électrique Inc. for all your electrical installation and connection needs.</h5>
          <h5 className="regions">Main regions of service: Laurentians, Lanaudiere and Laval</h5>
        </div>
    )
}

export default Overlay;