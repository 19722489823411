const Overlay = () => {
    return (  
        <div className="overlay">
          <div className="general">
            <div className="backdrop">
              <h1 className="responsive-headline">Residential Electrician Services</h1>
              <h5>At VB Electrique Inc. we understand the importance of a safe and functional electrical system in your home. Our team of licensed and experienced electricians is here to provide reliable and professional electrical services to homeowners in Blainville.</h5>
            </div>
          </div>
        </div>
    )
}

export default Overlay;