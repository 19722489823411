import { useLayoutEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { waypointLeave } from '../init';
import Header from './Header';
import Footer from './Footer';

const Layout = (props) => {
  useLayoutEffect(()=>{
    if (window.scrollY > document.querySelector('.home-under-the-fold').getBoundingClientRect().top) {
      waypointLeave();
    }
  },[]);

  return (
    <div>
      <Header/>
      <div className="mainContent">
        <Outlet />
      </div>
      <Footer data={props.footer}/>
    </div>
  );
};

export default Layout;