import React, { useState } from "react";
import Switch from "react-switch";

const LanguageSwitch = () => {
  const [checked, setChecked] = useState(getCheckedLang());

  const handleChange = nextChecked => {
    setChecked(nextChecked);
    if (nextChecked === true) {
      localStorage.removeItem('lang');
      localStorage.setItem('lang', 'fr');
      getNewPathname();
    } else {
      localStorage.removeItem('lang');
      localStorage.setItem('lang', 'en');
      getNewPathname();
    }
  };

  const routeListEn = {
    "acceuil": "home",
    "services-residentiel": "residential",
    "services-commercial": "commercial",
    "generatrice-de-secours": "standby-generators",
    "generatrice-portative": "portable-generators",
    "a-propos": "about",
    "contactez-nous": "contact"
  }

  const routeListFr = {
    "home": "acceuil",
    "residential": "services-residentiel",
    "commercial": "services-commercial",
    "standby-generators": "generatrice-de-secours",
    "portable-generators": "generatrice-portative",
    "about": "a-propos",
    "contact": "contactez-nous"
  }

  function getLang() {
    if (localStorage.getItem('lang')) {
      return localStorage.getItem('lang');
    } else {
      localStorage.setItem('lang', navigator.language.slice(0,2));
      return navigator.language.slice(0,2);
    }
  }

  function getCheckedLang() {
    let oldPathname = window.location.pathname.slice(1);
    let lang = getLang();

    if (!oldPathname.length && lang === 'fr') {
      window.location.pathname = '/acceuil';
      return;
    } else if (!oldPathname.length && lang === 'en') {
      window.location.pathname = '/home';
      return;
    }

    if (lang.includes('fr')) {
      return true;
    } else {
      return false;
    }

  }

  function getNewPathname() {
    let oldPathname = window.location.pathname.slice(1);

    if (!oldPathname.length) {
      window.location.pathname = '/';
      return;
    }

    if (getLang() === 'en') {
      window.location.pathname = '/' + routeListEn[oldPathname]
    } else {
      window.location.pathname = '/' + routeListFr[oldPathname]
    }
  }

  return (
    <label>
      <Switch 
          onChange={handleChange}
          checked={checked}
          onColor="#9F1223"
          offColor="#9F1223"
          width={60}
          height={28}
          checkedIcon={<p style={{margin: "1px 0 0 7px", color: "#fff", paddingTop: "2px"}}>FR</p>}
          uncheckedIcon={<p style={{margin: "2px 0 0 2px", color: "#fff"}}>EN</p>}
          checkedHandleIcon={<img src={"/media/iconlogo.png"} alt="Logo" height="25" style={{marginTop: "-1px"}}/>}
          uncheckedHandleIcon={<img src={"/media/iconlogo.png"} alt="Logo" height="25" style={{marginTop: "-1px"}}/>}
      />
    </label>
  );
}

export default LanguageSwitch;