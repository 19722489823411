export const englishMenuItems = [
  {
    title: "Home",
    url: "/home",
  },
  {
    title: "Services ↓",
    submenu: [
      {
        title: "Residential",
        url: "/residential",
      },
      {
        title: "Commercial",
        url: "/commercial",
      },
      {
        title: "Standy-by Generators",
        url: "/standby-generators",
      },
      {
        title: "Portable Generators",
        url: "/portable-generators",
      },
    ],
  },
  {
    title: "About",
    url: "/about",
  },
  {
    title: "Contact Us",
    url: "/contact",
  },
];

export const frenchMenuItems = [
  {
    title: "Acceuil",
    url: "/acceuil",
  },
  {
    title: "Services ↓",
    submenu: [
      {
        title: "Residentiel",
        url: "/services-residentiel",
      },
      {
        title: "Commercial",
        url: "/services-commercial",
      },
      {
        title: "Génératrice de secours",
        url: "/generatrice-de-secours",
      },
      {
        title: "Génératrice portative",
        url: "/generatrice-portative",
      },
    ],
  },
  {
    title: "A Propos",
    url: "/a-propos",
  },
  {
    title: "Contactez-nous",
    url: "/contactez-nous",
  },
];