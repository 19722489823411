import Services from "./Services";
import { AttentionSeeker } from "react-awesome-reveal";
import { Helmet } from "react-helmet";

const MainContent = () => {
    const list = [
        {
            "title": "Site Assessment",
            "description": "Before we begin the installation process, our electricians will conduct a site assessment to determine the best location for your generator. We will also evaluate your power requirements and help you choose the right size and type of generator to meet your needs."
        },
        {
            "title": "Generator Installation",
            "description": "Our electricians will install the generator according to the manufacturer's specifications and local codes and regulations. We'll also install a transfer switch that will automatically switch your power source to the generator when the power goes out."
        }
    ]

    return (
        <div>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Portable Generator Installation for Homes</title>
            <link rel="canonical" href="portable-generators" />
        </Helmet>
        <Services data={list}/>

        <section className="light-theme-row">
            <div className="content container">
                <AttentionSeeker effect="pulse" duration={1300}>
                    <div className="section-text">At VB Electrique Inc. we take pride in delivering top-quality portable generator installation services with a focus on customer satisfaction. With our installation services, you can rest assured that you'll have reliable backup power in the event of a power outage. Contact us today to schedule a service or consultation with one of our licensed electricians.</div>
                </AttentionSeeker>
            </div>
        </section>
    </div>
    )
}

export default MainContent;