import Navbar from './Nav/Navbar';

const Header = () => {
    return (
      <header>
        <div className="nav-area above-the-fold">
            <Navbar />
        </div>
      </header>
    );
  };
  
  export default Header;