import React, { useState } from 'react';

const Services = (props) => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("Hover over icon to reveal service details");
    
    const serviceSet1 = props.data.sectionparagraphlist.slice(0, 3).map(function (service) {
        return (
            <div className="col" key={service.title}>
                <div className="home-list-icon" onMouseOver={() => setTitle(service.title) + setDescription(service.description)}>
                    <img src={'/media/' + service.icon} alt={service.title} style={{ display: "block", margin: "0 auto", height: "100px", width: "100px"}}/>
                </div>
            </div>
        );
    });

    const serviceSet2 = props.data.sectionparagraphlist.slice(3, 6).map(function (service) {
        return (
            <div className="col" key={service.title}>
                <div className="home-list-icon" onMouseOver={() => setTitle(service.title) + setDescription(service.description)}>
                    <img src={'/media/' + service.icon} alt={service.title} style={{ display: "block", margin: "0 auto", height: "100px", width: "100px"}}/>
                </div>
            </div>
        );
    });

    return (
        <div className="row service-item">
            <div className="col-4">
                {serviceSet1}
            </div>
            <div className="col col-4" style={{alignSelf: "center"}}>
                <strong>{title}</strong>
                <div>{description}</div>
            </div>
            <div className="col-4">
                {serviceSet2}
            </div>
        </div>
    )
}

 export default Services;