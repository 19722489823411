import Services from "./Services";
import { Helmet } from "react-helmet";

const MainContent = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>VB Électrique Inc. - À propos</title>
                <link rel="canonical" href="a-propos" />
            </Helmet>
            <Services />
        </div>
    )
}

export default MainContent;