export const waypointEnter = () => {
    // eslint-disable-next-line
    document.querySelector('.nav-area').classList.remove('under-the-fold');
    document.querySelector('.nav-area').classList.add('above-the-fold');
}

export const waypointLeave = () => {
    // eslint-disable-next-line
    document.querySelector('.nav-area').classList.add('under-the-fold');
    document.querySelector('.nav-area').classList.remove('above-the-fold');
}

export const closeAllMenusOnEsc = () => {
    // eslint-disable-next-line
    $('#react-burger-cross-btn').trigger('click');
}