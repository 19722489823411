import Services from "./Services";
import { AttentionSeeker } from "react-awesome-reveal";
import { Helmet } from "react-helmet";

const MainContent = () => {
    const list = [
        {
            "title": "Site Assessment",
            "description": "Before we begin the installation process, our electricians will conduct a site assessment to determine the best location for your generator. We will also evaluate your power requirements and help you choose the right size and type of generator to meet your needs."
        },
        {
            "title": "Generator Installation",
            "description": "Our electricians will install the generator according to the manufacturer's specifications and local codes and regulations. We'll also install a transfer switch that will automatically switch your power source to the generator when the power goes out."
        },
        {
            "title": "Generator Maintenance and Repair",
            "description": "We understand the importance of keeping your generator in good working condition. That's why we offer routine maintenance services to ensure that your generator is ready to operate when you need it. We can also repair any issues with your generator to keep it running smoothly."
        },
        {
            "title": "Generator Replacement",
            "description": "If your existing generator is no longer reliable or efficient, our team can help you replace it with a new Champion stand-by generator. We'll remove the old generator, install the new one, and ensure that it meets all safety codes and regulations."
        }
    ]

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Champion Stand-by Generator Installation</title>
                <link rel="canonical" href="standby-generators" />
            </Helmet>
            <Services data={list}/>

            <section className="light-theme-row">
                <div className="content container">
                    <AttentionSeeker effect="pulse" duration={1300}>
                        <div className="section-text">At VB Electrique Inc. we take pride in delivering top-quality Champion stand-by generator installation services with a focus on customer satisfaction. With our installation services, you can rest assured that you'll have reliable power in the event of a power outage. Contact us today to schedule a service or consultation with one of our licensed electricians.</div>
                    </AttentionSeeker>
                </div>
            </section>
    </div>
    )
}

export default MainContent;