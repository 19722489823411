import { AttentionSeeker } from "react-awesome-reveal";
import Services from "./Services";
import MobileServices from "./MobileServices";
import { Helmet } from "react-helmet";

const MainContent = () => {

    const data = {
        sectionparagraphlist: [
            {
                "id": 1,
                "title": "Services électriques résidentiels",
                "description": "Nos électriciens agréés fournissent une gamme de services électriques résidentiels, y compris le câblage et le recâblage, les mises à niveau des panneaux, les installations d'éclairage, etc.",
                "icon": "residential.svg"
            },
            {
                "id": 2,
                "title": "Services électriques commerciaux",
                "description": "Nous proposons des solutions électriques complètes pour répondre aux besoins uniques des entreprises, y compris la maintenance électrique, la conception de l'éclairage et des solutions écoénergétiques.",
                "icon": "commercial.svg"
            },
            {
                "id": 3,
                "title": "Installation de chargeurs de voiture électrique",
                "description": "Nous fournissons des services d'installation de chargeurs de voiture électrique pour vous assurer que vous pouvez recharger votre véhicule électrique à la maison ou au travail.",
                "icon": "electric-car-charger.svg"
            },
            {
                "id": 4,
                "title": "Installation de génératrices de secours",
                "description": "Nous fournissons des services d'installation, d'entretien et de réparation pour les génératrices de secours afin de garantir que votre maison ou votre entreprise dispose d'une alimentation fiable en cas de panne de courant.",
                "icon": "standby-generators.svg"
            },
            {
                "id": 5,
                "title": "Installation de génératrices portatives",
                "description": "Nous installons des génératrices portatives pour fournir une alimentation de secours à votre domicile ou votre entreprise pendant les pannes de courant.",
                "icon": "portable-generators.svg"
            },
            {
                "id": 6,
                "title": "Branchement du spa et de la piscine",
                "description": "Notre équipe peut installer des systèmes électriques pour alimenter votre spa ou votre piscine, vous permettant ainsi de profiter de votre oasis extérieure en toute sécurité.",
                "icon": "hot-tubs.svg"
            }
        ]
      }

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Bienvenue chez VB Électrique Inc.</title>
                <link rel="canonical" href="acceuil" />
            </Helmet>
            <section className="light-theme-row">
                <div className="content container">
                    <AttentionSeeker effect="pulse" duration={1300}>
                        <h1>Bienvenue chez VB Électrique Inc.</h1>
                        <div className="section-text">VB Électrique Inc. est un fournisseur autorisé en services électriques résidentiels et commerciaux à Blainville et ses environs. Nous sommes spécialisés dans la fourniture de solutions électriques de qualité supérieure, innovantes, fiables et rentables.</div>
                    </AttentionSeeker>
                </div>
            </section>
            <section className="dark-theme-row">
                <div className="content container">
                    <AttentionSeeker effect="pulse" duration={1300}>
                        <div className="section-title">Nos services</div>
                        <div className="section-text service-list">Nous offrons une large gamme de services électriques pour répondre aux besoins de nos clients, notamment :</div>
                        <div className="mobile">
                            <MobileServices data={data}/>
                        </div>
                        <div className="desktop">
                            <Services data={data}/>
                        </div>
                    </AttentionSeeker>
                </div>
            </section>
            <section className="light-theme-row">
                <div className="content container">
                    <AttentionSeeker effect="pulse" duration={1300}>
                        <div className="section-title">Notre équipe</div>
                        <p className="section-text">Notre équipe est composée d'électriciens agréés et expérimentés qui se consacrent à fournir un service client exceptionnel. Nous sommes équipés des outils derniers cri et technologies pour fournir des solutions de haute qualité à nos clients.</p>
                    </AttentionSeeker>
                </div>
            </section>
            <section className="dark-theme-row">
                <div className="content container">
                    <AttentionSeeker effect="pulse" duration={1300}>
                        <div className="section-title">Pourquoi nous choisir?</div>
                        <p className="section-text">Chez VB Électrique Inc, nous nous engageons à fournir des solutions électriques innovantes et fiables qui répondent aux besoins uniques de nos clients. Nous sommes fiers de fournir des services de qualité supérieure en mettant l'accent sur un service client exceptionnel. Nous offrons également des prix compétitifs et une garantie de satisfaction.</p>
                    </AttentionSeeker>
                </div>
            </section>
            <section className="light-theme-row">
                <div className="content container">
                    <AttentionSeeker effect="pulse" duration={1300}>
                        <div className="section-title">Contactez-nous</div>
                        <p className="section-text">Si vous avez des questions sur nos services ou souhaitez planifier une consultation avec l'un de nos électriciens agréés, veuillez nous contacter dès aujourd'hui. Nous sommes impatients de vous servir et de dépasser vos attentes.</p>
                    </AttentionSeeker>
                </div>
            </section>
        </div>
    )
}

export default MainContent;