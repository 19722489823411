import { AttentionSeeker } from "react-awesome-reveal";
import Services from "./Services";
import MobileServices from "./MobileServices";
import { Helmet } from "react-helmet";

const MainContent = () => {

    const data = {
        sectionparagraphlist: [
            {
                "id": 1,
                "title": "Residential Electrical Services",
                "description": "Our licensed electricians provide a range of residential electrical services, including wiring and rewiring, panel upgrades, lighting installations, and more.",
                "icon": "residential.svg"
            },
            {
                "id": 2,
                "title": "Commercial Electrical Services",
                "description": "We offer comprehensive electrical solutions to meet the unique needs of businesses, including electrical maintenance, lighting design, and energy-efficient solutions.",
                "icon": "commercial.svg"
            },
            {
                "id": 3,
                "title": "Electric Car Charger Installation",
                "description": "We provide installation services for electric car chargers to ensure that you can charge your electric vehicle at home or work.",
                "icon": "electric-car-charger.svg"
            },
            {
                "id": 4,
                "title": "Stand-by Generator Installation",
                "description": "We provide installation, maintenance, and repair services for stand-by generators to ensure that your home or business has reliable power in the event of a power outage.",
                "icon": "standby-generators.svg"
            },
            {
                "id": 5,
                "title": "Portable Generator Installation",
                "description": "We install portable generators to provide backup power to your home or business during power outages.",
                "icon": "portable-generators.svg"
            },
            {
                "id": 6,
                "title": "Hot Tub and Pool Hookup",
                "description": "Our team can install electrical systems to power your hot tub or pool, ensuring that you can enjoy your outdoor oasis safely.",
                "icon": "hot-tubs.svg"
            }
        ]
      }

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Welcome to VB Electrique Inc</title>
                <link rel="canonical" href="home" />
            </Helmet>
            <section className="light-theme-row">
                <div className="content container">
                    <AttentionSeeker effect="pulse" duration={1300}>
                        <h1>Welcome to VB Electrique Inc</h1>
                        <p>VB Electrique Inc. is a licensed provider of residential and commercial electrical services in Blainville and surrounding areas. We specialize in providing top-quality electrical solutions that are innovative, reliable, and cost-effective.</p>
                    </AttentionSeeker>
                </div>
            </section>
            <section className="dark-theme-row">
                <div className="content container">
                    <AttentionSeeker effect="pulse" duration={1300}>
                        <div className="section-title">Our Services</div>
                        <div className="section-text service-list">We offer a wide range of electrical services to meet the needs of our customers, including:</div>
                        <div className="mobile">
                            <MobileServices data={data}/>
                        </div>
                        <div className="desktop">
                            <Services data={data}/>
                        </div>
                    </AttentionSeeker>
                </div>
            </section>
            <section className="light-theme-row">
                <div className="content container">
                    <AttentionSeeker effect="pulse" duration={1300}>
                        <div className="section-title">Our Team</div>
                        <p className="section-text">Our team consists of licensed and experienced electricians who are dedicated to providing exceptional customer service. We are equipped with the latest tools and technology to deliver top-quality solutions to our customers.</p>
                    </AttentionSeeker>
                </div>
            </section>
            <section className="dark-theme-row">
                <div className="content container">
                    <AttentionSeeker effect="pulse" duration={1300}>
                        <div className="section-title">Why Choose Us?</div>
                        <p className="section-text">At VB Electrique Inc., we are committed to delivering innovative and reliable electrical solutions that meet the unique needs of our customers. We take pride in providing top-quality services with a focus on exceptional customer service. We also offer competitive pricing and a satisfaction guarantee.</p>
                    </AttentionSeeker>
                </div>
            </section>
            <section className="light-theme-row">
                <div className="content container">
                    <AttentionSeeker effect="pulse" duration={1300}>
                        <div className="section-title">Contact Us</div>
                        <p className="section-text">If you have any questions about our services or would like to schedule a consultation with one of our licensed electricians, please contact us today. We look forward to serving you and exceeding your expectations.</p>
                    </AttentionSeeker>
                </div>
            </section>
        </div>
    )
}

export default MainContent;