const Overlay = () => {
    return (  
        <div className="overlay">
          <div className="general generator">
            <div className="backdrop">
              <h1 className="responsive-headline">Installation génératrice portative pour les maisons</h1>
              <h5>Chez VB Electrique Inc, nous comprenons que les pannes de courant peuvent être frustrantes et perturbatrices. C'est pourquoi nous proposons des services d'installation de génératrices portables pour fournir une alimentation de secours fiable à votre domicile en cas de panne de courant. Nos électriciens agréés et expérimentés peuvent vous aider à choisir la bonne taille et le bon type de générateur pour répondre à vos besoins spécifiques.</h5>
            </div>
          </div>
        </div>
    )
}

export default Overlay;