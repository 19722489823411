import ContactForm from "./ContactForm";
import { Helmet } from "react-helmet";

const MainContent = () => {
    return (
        <div>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Contact Us</title>
            <link rel="canonical" href="contact" />
        </Helmet>
        <section className="dark-theme-row">
            <div className="content container">
                <div className="row">
                    <div className="section-text" style={{paddingBottom: "1em"}}>
                        If you have any questions or would like to schedule a consultation with one of our licensed electricians, please contact us using one of the following methods: 
                    </div>
                    <hr />
                    <div style={{textAlign: "center",lineHeight: "20px",marginTop: "1em"}}>
                            <p>Phone: <a href="tel:5146624693">(514) 662-4693</a></p>
                            <p>Email: <a href="mailto:info@vbelectrique.com">info@vbelectrique.com</a></p>
                            <p>Address: 6 Place d'Argenson, Blainville J7C 4H2 Quebec</p>
                        </div>
                </div>
            </div>
        </section>

        <section className="light-theme-row">
            <div className="content container">
                <div className="row">
                    <div className="section-text">Please contact us for more information about our products / services or to schedule a consultation with one of our licensed electricians.</div>
                </div>
                <ContactForm />
            </div>
        </section>
    </div>
    )
}

export default MainContent;