import Services from "./Services";
import { Helmet } from "react-helmet";

const MainContent = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>VB Electrique Inc. - About Us</title>
                <link rel="canonical" href="about" />
            </Helmet>
            <Services />
        </div>
    )
}

export default MainContent;