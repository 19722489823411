import { AttentionSeeker } from "react-awesome-reveal";

const Services = (props) => {
    if (!props.data) return null;

    const services = props.data.map((service, i) => {
        let divClass = i %2 === 0 ? 'light-theme-row' : 'dark-theme-row';

        return (
            <section className={divClass} key={i}>
                <div className="content container">
                    <AttentionSeeker effect="pulse" duration={1300}>
                        <div className="section-title">{service.title}</div>
                        <p className="section-text">{service.description}</p>
                    </AttentionSeeker>
                </div>
            </section>
        );
      });

      return (
        <div>
            {services}
        </div>
      )
}

export default Services;