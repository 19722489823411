import { React, Component} from 'react';
import { Routes, Route } from 'react-router-dom';

import EnLayout from './En-Components/Layout';
import EnHome from './routes/En-Home';
import EnServicesResidentiel from './routes/En-ServicesResidential';
import EnServicesCommercial from './routes/En-ServicesCommercial';
import EnServicesStandbyGenerator from './routes/En-ServicesStandbyGenerator';
import EnServicesPortableGenerator from './routes/En-ServicesPortableGenerator';
import EnAbout from './routes/En-About';
import EnContact from './routes/En-Contact';

import FrLayout from './Fr-Components/Layout';
import FrHome from './routes/Fr-Home';
import FrServicesResidentiel from './routes/Fr-ServicesResidential';
import FrServicesCommercial from './routes/Fr-ServicesCommercial';
import FrServicesStandbyGenerator from './routes/Fr-ServicesStandbyGenerator';
import FrServicesPortableGenerator from './routes/Fr-ServicesPortableGenerator';
import FrAbout from './routes/Fr-About';
import FrContact from './routes/Fr-Contact';

class App extends Component {

  render() {
    return (
      <>
        <Routes>
          <Route path="/" location="/" element={<EnLayout />}>
            <Route index path="/home" element={<EnHome />} />
            <Route path="/residential" element={<EnServicesResidentiel />} />
            <Route path="/commercial" element={<EnServicesCommercial />} />
            <Route path="/standby-generators" element={<EnServicesStandbyGenerator />} />
            <Route path="/portable-generators" element={<EnServicesPortableGenerator  />} />
            <Route path="/about" element={<EnAbout />} />
            <Route path="/contact" element={<EnContact />} />
          </Route>
          <Route path="/" location="/" element={<FrLayout />}>
            <Route index path="/acceuil" element={<FrHome />} />
            <Route path="/services-residentiel" element={<FrServicesResidentiel />} />
            <Route path="/services-commercial" element={<FrServicesCommercial />} />
            <Route path="/generatrice-de-secours" element={<FrServicesStandbyGenerator />} />
            <Route path="/generatrice-portative" element={<FrServicesPortableGenerator  />} />
            <Route path="/a-propos" element={<FrAbout />} />
            <Route path="/contactez-nous" element={<FrContact />} />
          </Route>
        </Routes>
      </>
    );
  }
}

export default App;