import { Waypoint } from "react-waypoint";
import { waypointEnter, waypointLeave } from "../init";
import MainContent from "../Fr-Components/PageElements/About/MainContent";
import Video from "../Fr-Components/PageElements/About/Video";
import Overlay from "../Fr-Components/PageElements/About/Overlay";

const About = () => {
  return (
    <div>
      <div className="home-above-the-fold">
        <Video />
        <Overlay />
      </div>
      <Waypoint onEnter={waypointEnter} onLeave={waypointLeave} topOffset="50px"/>
      <div className="home-under-the-fold">
        <MainContent />
      </div>
    </div>
    );
  };
  
export default About;