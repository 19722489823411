const Overlay = () => {
    return (  
        <div className="overlay">
          <div className="general generator">
            <div className="backdrop">
              <h1 className="responsive-headline">Installation de génératrice de secours Champion</h1>
              <h5>Chez VB Electrique Inc, nous proposons des services d'installation de génératrices de secours Champion pour fournir une alimentation fiable et continue à votre domicile ou votre entreprise en cas de panne de courant. Nos électriciens agréés et expérimentés peuvent vous aider à choisir la bonne taille et le bon type de générateur pour répondre à vos besoins spécifiques.</h5>
            </div>
          </div>
        </div>
    )
}

export default Overlay;