import Services from "./Services";
import { AttentionSeeker } from "react-awesome-reveal";
import { Helmet } from "react-helmet";

const MainContent = () => {
    const list = [
        {
            "title": "Évaluation du site",
            "description": "Avant de commencer le processus d'installation, nos électriciens procéderont à une évaluation du site afin de déterminer le meilleur emplacement pour votre génératrice. Nous évaluerons également vos besoins en énergie et vous aiderons à choisir la bonne taille et le bon type de générateur pour répondre à vos besoins."
        },
        {
            "title": "Installation génératrice",
            "description": "Nos électriciens installeront la génératrice conformément aux spécifications du fabricant et aux codes et règlements locaux. Nous installerons également un commutateur de transfert qui commutera automatiquement votre source d'alimentation vers le générateur en cas de panne de courant."
        }
    ]

    return (
        <div>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Installation génératrice portative pour les maisons</title>
            <link rel="canonical" href="generatrice-portative" />
        </Helmet>
        <Services data={list}/>

        <section className="light-theme-row">
            <div className="content container">
                <AttentionSeeker effect="pulse" duration={1300}>
                    <div className="section-text">Chez VB Electrique Inc, nous sommes fiers de fournir des services d'installation de génératrices portables de qualité supérieure en mettant l'accent sur la satisfaction du client. Avec nos services d'installation, vous pouvez être assuré d'avoir une alimentation de secours fiable en cas de panne de courant. Contactez-nous dès aujourd'hui pour planifier un service ou une consultation avec l'un de nos électriciens agréés.</div>
                </AttentionSeeker>
            </div>
        </section>
    </div>
    )
}

export default MainContent;