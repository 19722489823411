const Overlay = () => {
    return (  
        <div className="overlay">
          <div className="general">
            <div className="backdrop">
              <h1 className="responsive-headline">Services d'électricien résidentiel</h1>
              <h5>Chez VB Électrique Inc. nous comprenons l'importance d'un système électrique sécuritaire et fonctionnel dans votre maison. Notre équipe d'électriciens agréés et expérimentés est là pour fournir des services électriques fiables et professionnels aux propriétaires de Blainville et les alentours.</h5>
            </div>
          </div>
        </div>
    )
}

export default Overlay;