import Services from "./Services";
import { AttentionSeeker } from "react-awesome-reveal";
import { Helmet } from "react-helmet";

const MainContent = () => {
    const data = {
        sectionparagraphlist: [
            {
                "title": "Installations électriques",
                "description": "Si vous ouvrez une nouvelle entreprise ou rénovez un espace existant, nos électriciens peuvent gérer tous vos besoins d'installation électrique. Nous pouvons installer de nouveaux systèmes électriques, y compris le câblage, les panneaux, les disjoncteurs, etc. Nous installons également des luminaires, des ventilateurs de plafond et d'autres appareils électriques."
            },
            {
                "title": "Réparations électriques et entretien",
                "description": "Si votre système électrique rencontre des problèmes ou montre des signes d'usure, nos électriciens peuvent vous aider. Nous pouvons dépanner et réparer tout problème avec votre câblage, vos interrupteurs, vos prises et votre éclairage. Nous offrons également des services d'entretien courant pour assurer le bon fonctionnement de votre système électrique."
            },
            {
                "title": "Mises à niveau électriques",
                "description": "Si votre entreprise a besoin d'énergie supplémentaire ou si vous cherchez à mettre à niveau votre système électrique vers un système plus efficace et plus rentable, nous pouvons vous aider. Notre équipe peut installer de nouveaux circuits, panneaux, transformateurs et autres appareils électriques pour répondre à vos besoins spécifiques."
            },
            {
                "title": "Mises à niveau et rénovations d'éclairage",
                "description": "Si vous cherchez à améliorer votre éclairage pour économiser de l'énergie et réduire les coûts, nous proposons une gamme de solutions d'éclairage, y compris des rénovations et des installations LED. Nous pouvons également installer des détecteurs de présence et d'autres commandes d'éclairage pour maximiser vos économies d'énergie."
            }
        ]
    }

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Services d'électricien commercial</title>
                <link rel="canonical" href="services-commercial" />
            </Helmet>
            <Services data={data.sectionparagraphlist}/>

            <section className="light-theme-row">
                <div className="content container">
                    <AttentionSeeker effect="pulse" duration={1300}>
                        <div className="section-text">Chez VB Électrique Inc., nous sommes fiers de fournir des services d'électriciens commerciaux de qualité supérieure en mettant l'accent sur la satisfaction du client. Contactez-nous dès aujourd'hui pour planifier un service ou une consultation avec l'un de nos électriciens agréés.</div>
                    </AttentionSeeker>
                </div>
            </section>
    </div>
    )
}

export default MainContent;