import { React } from "react";

const DATA = ['residential1.mp4','residential2.mp4','residential3.mp4'];

const Video = () => {
    return (
        <video 
            src={"/media/" + DATA[Math.floor(Math.random()*DATA.length)]} 
            muted 
            autoPlay 
            loop 
            style={{
            width: "100%",
            height: "100vh",
            objectFit: "cover",
            zIndex: "1",
            overflow: "hidden"
            }}
        />
    )
}
export default Video;